import { useState } from 'react';

function Idea({ governorIns, account }) {
    const [idea, setIdea] = useState("");

    const ideaProposal = async () => {
        const functionJSONInterface = {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"
                }
            ],
            "name": "transfer",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        }
        const calldata = window.web3.eth.abi.encodeFunctionCall(
            functionJSONInterface,
            ["0x655A01934e1CAf35EB3fB2659aa0af6D62A98B13", "0"]
        );
        const tx = await governorIns.methods.propose(
            ["0x04F46e4B65EB22caC3958ed70765774ec32f37b2"],
            [0],
            [calldata],
            idea
        ).send({ from: account });
    }
    const onChangeHandler = (e) => {
        setIdea(e.target.value)
    }
    return (
        <div className="form">
            <div className="title">IDEA</div>
            <div className="subtitle"></div>
            <div className="textarea-container ic1">
                <textarea  
                    id="idea" 
                    className="textarea" 
                    type="text" 
                    placeholder=" "
                    cols="40"
                    rows="5"
                    onChange={onChangeHandler}
                >
                </textarea>
                <div className="cut"></div>
                <label htmlFor="idea" className="placeholder">IDEA</label>
            </div>
            <button type="text" className="submit" onClick={ideaProposal}>Propose</button>
        </div>
    )
}
export default Idea;