
import ProposalDetails from './ProposalDetails';
import { votingDelay, votingPeriod, timeLock, apiUrl } from '../../helpers/backendinfo';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { useEffect, useState } from 'react';

function Article({ articleClass, p, timer, governorIns, account }) {
    const [proposalHistory, setProposalHistory] = useState();
    useEffect(() => {
        async function load() {
            const pH = await proposalHistorySection(p);
            setProposalHistory(pH);
        }
        load();
    }, []);
    const proposalHistorySection = async (proposal) => {
        let history = ``;
        const blockInfo = await window.web3.eth.getBlock(proposal.blockNumber);
        history += `
            <p className="white-text">
                <a href="https://bscscan.com/tx/${proposal.transactionHash}" target="_blank">
                    Created at ${new Date(blockInfo.timestamp * 1000)}
                </a>
            </p>
        `
        if (proposal.status == "Executed") {
            //active time
            history += `
            <p className="white-text">
                Active at ${new Date(blockInfo.timestamp * 1000 + (votingDelay * 3000))}
            </p>
            `
            //succeeded time
            history += `
            <p className="white-text">
                Succeeded at ${new Date(blockInfo.timestamp * 1000 + ((votingDelay + votingPeriod) * 3000))}
            </p>
            `
            //queue time - tx hash
            if (proposal.queueTstmp) {
                history += `
                <p className="white-text">
                <a href="https://bscscan.com/tx/${proposal.queueTxHash}" target="_blank">
                    Queueed at ${new Date(proposal.queueTstmp * 1000)}
                    </a>
                </p>
                `
            }
            //execute time - tx hash
            if (proposal.executeTstmp) {
                history += `
                <p className="white-text">
                <a href="https://bscscan.com/tx/${proposal.executeTxHash}" target="_blank">
                    Executed at ${new Date(proposal.executeTstmp * 1000)}
                </a>
                </p>
                `
            }
        } else if (proposal.status == "Queued") {
            //active time
            history += `
            <p className="white-text">
                Active at ${new Date(blockInfo.timestamp * 1000 + (votingDelay * 3000))}
            </p>
            `
            //succeeded time
            history += `
            <p className="white-text">
                Succeeded at ${new Date(blockInfo.timestamp * 1000 + ((votingDelay + votingPeriod) * 3000))}
            </p>
            `
            //queue time - tx hash
            if (proposal.queueTstmp) {
                history += `
                <p className="white-text">
                <a href="https://bscscan.com/tx/${proposal.queueTxHash}" target="_blank">
                    Queueed at ${new Date(proposal.queueTstmp * 1000)}
                    </a>
                </p>
                `
            }
        } else if (proposal.status == "Defeated") {
            //active time
            history += `
            <p className="white-text">
                Active at ${new Date(blockInfo.timestamp * 1000 + (votingDelay * 3000))}
            </p>
            `
            //Defeated time
            history += `
            <p className="white-text">
                Defeated at ${new Date(blockInfo.timestamp * 1000 + ((votingDelay + votingPeriod) * 3000))}
            </p>
            `
        } else if (proposal.status == "Canceled") {
            //active time
            history += `
            <p className="white-text">
                Active at ${new Date(blockInfo.timestamp * 1000 + (votingDelay * 3000))}
            </p>
            `
            //cancel time - tx hash
            if (proposal.cancelTstmp) {
                history += `
                <p className="white-text">
                <a href="https://bscscan.com/tx/${proposal.cancelTxHash}" target="_blank">
                    Canceled at ${new Date(proposal.cancelTstmp * 1000)}
                    </a>
                </p>
                `
            }
        }
        return history;
    }
    return (
        <div>
        <article className={articleClass}>
            <ul>
                <li><a href={"https://bscscan.com/tx/" + p.transactionHash} target="_blank">{p.description.slice(0,40)}...</a></li>
                <li>{p.status}</li>
            </ul>
            <ul className="more-content">
                <li><div>FOR</div><div>{Number(p.forVotes / 1e18).toFixed(3)}</div></li>
                <li><div>AGAINST</div><div>{Number(p.againstVotes / 1e18).toFixed(3)}</div></li>
                <li>
                    <a className="button-29" href={"#" + p.proposalId}>Details</a>
                </li>
            </ul>
        </article>
            <div id={p.proposalId} className="overlay">
                <div className="popup">
                    <h2>Proposal Details</h2>
                    <a className="close" href="#">&times;</a>
                    <div className="content">
                        <ProposalDetails
                            governorIns={governorIns}
                            p={p}
                            account={account}
                            history={proposalHistory}
                            timer={timer}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Article;