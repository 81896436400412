import './App.css';
import React, { useEffect, useState } from 'react';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import loadWeb3 from './helpers/loadWeb3';
import loadBCData from './helpers/loadBCData';
import loadContracts from './helpers/loadContracts';
import Navbar from './components/Navbar';
import ProposalList from './components/ProposalList/ProposalList';
import GovernanceOverview from './components/GovernanceOverview';
import CreateProposal from './components/CreateProposal/CreateProposal';

function App() {
  const [account, setAccount] = useState("0x0")
  const [reitGBalance, setReitGBalance] = useState(0);
  const [reitGTS, setReitGTS] = useState(0);
  const [netId, setNetworkId] = useState(-1);
  const [isContractsLoaded, setIsContractsLoaded] = useState(false);
  const [tlcIns, setTlcIns] = useState([]);
  const [governorIns, setGovernorIns] = useState([]);
  const [reitGIns, setReitGIns] = useState([]);
  if (window.ethereum) {
    window.ethereum.on('accountsChanged', (accounts) => {
      // Time to reload interface with accounts[0]!
      setAccount(accounts[0]);
    })
    // detect Network account change
    window.ethereum.on('chainChanged', (netId) => {
      setNetworkId(netId);
      setIsContractsLoaded(false);
    });
  }
  useEffect(() => {
    async function load() {
      await loadWeb3();
      const netId = await loadBCData(setAccount);
      console.log("useEffect " + netId)
      const chainId = await window.web3.eth.getChainId();
      //alert("CHAIN ID IS : "+chainId);
      //check kovan network
      if (netId !== 56) {
        alertify.set('notifier','position', 'bottom-left');
        alertify.notify("Please change yor network to Binance Smart Chain", 'error', 5);
      } else {
        setNetworkId(netId);
        const result = await loadContracts(
          setReitGIns,
          setGovernorIns,
          setTlcIns
        );
        setIsContractsLoaded(result);
      }
    }
    load();

  }, []);
  return (
    <BrowserRouter>
    <div className="App">
      <Navbar/>
      <Routes>
        <Route 
           exact path='/' 
          element={
            <ProposalList 
              governorIns={governorIns} 
              isContractsLoaded={isContractsLoaded} 
              account={account}
            />
          }
        />
        <Route 
          path='/overview' 
          element={
            <GovernanceOverview 
              reitGIns={reitGIns} 
              isContractsLoaded={isContractsLoaded} 
              account={account}
            />
          }
        />
        <Route 
          path='/create' 
          element={
            <CreateProposal 
              governorIns={governorIns} 
              account={account}
              isContractsLoaded={isContractsLoaded} 
            />
          }
        />

      </Routes>

    </div>
    </BrowserRouter>
  );
}

export default App;
/*
  const cancelProposal = async (e)=>{
    console.log("cancelProposal " + e.target.value);
    const functionJSONInterface = {
      name: 'setPendingImplementationDao',
      type: 'function',
      inputs: [{
        type: 'address',
        name: 'newImp'
      }]
    };
    let descriptionHash = window.web3.utils.utf8ToHex("Transfer Treasury : " + pendingImp);
    descriptionHash = window.web3.utils.keccak256(descriptionHash);
    const calldata = window.web3.eth.abi.encodeFunctionCall(functionJSONInterface, [pendingImp]);
    await governorIns.methods.cancel(
      [treasuryIns._address],
      [0],
      [calldata],
      descriptionHash
      ,
    ).send({ from: account });
  }

*/