import React, { useEffect, useState } from 'react';
import {timeLock , apiUrl } from '../../helpers/backendinfo';
import 'alertifyjs/build/css/alertify.css';
import Article from './Article';

function ProposalList({ governorIns, isContractsLoaded, account }) {
    const [currentBNumber, setCurrentBNumber] = useState(0);
    const [proposalArr, setProposalArr] = useState([]);
    useEffect(()=>{
        async function load(){
            await getProposalsAPI();
        }
        if(isContractsLoaded){
            load(); 
        }
    },[isContractsLoaded]);
    const getProposalsAPI = async () => {
        setCurrentBNumber(await window.web3.eth.getBlockNumber());
        //GET Proposal IDs and BN
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        let pdb = await fetch(`${apiUrl}/proposals`, options);
        pdb = await pdb.json();
        let ppWithStatus = [];
        for (const p of pdb.result) {
            //0-Pending,1-Active,2-Canceled,3-Defeated,4-Succeeded,5-Queued,6-Expired,7-Executed
            const statusArr = ["Pending", "Active", "Canceled", "Defeated", "Succeeded", "Queued", "Expired", "Executed"];
            let status = await governorIns.methods.state(p.proposalId).call();
            status = statusArr[status];
            let result = await governorIns.methods.proposalVotes(p.proposalId).call();
            ppWithStatus.push(Object.assign(
                {
                    proposalId : p.proposalId,
                    description : p.description,
                    transactionHash : p.transactionHash,
                    blockNumber : p.proposalBN,
                    endBlock : p.endBlock,
                    startBlock: p.startBlock,
                    targets:p.targets.split(","),
                    calldatas:p.calldatas.split(","),
                    status: status,
                    againstVotes: result.againstVotes,
                    forVotes: result.forVotes,
                    abstainVotes: result.abstainVotes,
                    executeTstmp:p.execute,
                    executeTxHash:p.executeTxHash,
                    queueTstmp:p.queue,
                    queueTxHash:p.queueTxHash,
                    cancelTstmp:p.cancel,
                    cancelTxHash:p.cancelTxHash
                }
            ))
        }
        console.log(ppWithStatus)
        let sortedArr = [...ppWithStatus].sort((a, b) => {
            return a.endBlock > b.endBlock ? -1 : 1;
        })
        setProposalArr(sortedArr);
    }
    const articleClassFunc=(state)=>{
        if (state == "Active") {
            return "row nhl";
        } else if (state == "Succeeded" || state == "Queued") {
            return "row mlb";
        } else if (state == "Canceled" || state == "Defeated") {
            return "row pga";
        } else if (state == "Expired") {
            return "row expr";
        } else if (state == "Executed") {
            return "row nfl";
        }else{
            return "row pndg";
        }
    }
    const timerFunc=(startBlock,endBlock,state)=>{
        //Voting delay
        if (startBlock > currentBNumber) {
            let timer = Math.abs(Number(currentBNumber) - Number(startBlock)) * 3;
            let seconds = Math.floor((timer));
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);
            let days = Math.floor(hours / 24);
            timer = "";
            timer += days !== 0 ?
                days == 1 ?
                    days + " day "
                    :
                    days + " days "
                :
                timer;
            timer += hours !== 0 ?
                hours == 1 ?
                    hours%24 + " hour "
                    :
                    hours%24 + " hours "
                :
                timer;

            timer += minutes !== 0 ?
                minutes == 1 ?
                    minutes%60  + " minute "
                    :
                    minutes%60 + " minutes "
                :
                timer;

            timer += seconds !== 0 ? seconds%60%60 + " seconds to start of voting" : timer;
            return timer;
        }
        //Voting
        else if (endBlock > currentBNumber) {
            let timer = Math.abs(Number(currentBNumber) - Number(endBlock)) * 3;
            let seconds = Math.floor((timer));
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);
            let days = Math.floor(hours / 24);
            timer = "";
            timer += days !== 0 ?
                days == 1 ?
                    days + " day "
                    :
                    days + " days "
                :
                timer;
            timer += hours !== 0 ?
                hours == 1 ?
                    hours%24 + " hour "
                    :
                    hours%24 + " hours "
                :
                timer;

            timer += minutes !== 0 ?
                minutes == 1 ?
                    minutes%60 + " minute "
                    :
                    minutes%60 + " minutes "
                :
                timer;

            timer += seconds !== 0 ? seconds%60%60 + " seconds left to vote" : timer;
            return timer;
        }
        //Timelock
        else if (
            (endBlock + timeLock/3) >currentBNumber 
            && 
            (state == "Succeeded" || state == "Queued")
            ) 
        {
            let timer = Math.abs(Number(currentBNumber) - Number(endBlock + timeLock/3)) * 3;
            let seconds = Math.floor((timer));
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);
            let days = Math.floor(hours / 24);
            seconds=seconds%60;
            minutes=minutes%60;
            hours=hours%24;
            
            timer = "";
            timer += days !== 0 ?
                days == 1 ?
                    days + " day "
                    :
                    days + " days "
                :
                timer;
            timer += hours !== 0 ?
                hours == 1 ?
                    hours + " hour "
                    :
                    hours + " hours "
                :
                timer;

            timer += minutes !== 0 ?
                minutes == 1 ?
                    minutes + " minute "
                    :
                    minutes + " minutes "
                :
                timer;

            timer += seconds !== 0 ? seconds + " seconds left to execute" : timer;
            return timer;
        }
        //ELSE???
    }
    return (
        <section className="wrapper">
            <main className="row title">
                <ul>
                    <li>Description</li>
                    <li>Status</li>
                </ul>
            </main>
            {
                proposalArr.length > 0 ?
                    proposalArr.map((p) => {
                        let articleClass = articleClassFunc(p.status);
                        //Timer
                        let timer=timerFunc(p.startBlock,p.endBlock,p.status);
                        return (
                            <React.Fragment key={p.proposalId}>
                                <Article
                                    articleClass={articleClass}
                                    governorIns={governorIns}
                                    account={account}
                                    p={p}
                                    timer={timer}
                                />
                            </React.Fragment>
                        )
                    })
                    :
                    <div className='loading-div'>
                        <div className="middle">
                            <div className="bar bar1"></div>
                            <div className="bar bar2"></div>
                            <div className="bar bar3"></div>
                            <div className="bar bar4"></div>
                            <div className="bar bar5"></div>
                            <div className="bar bar6"></div>
                            <div className="bar bar7"></div>
                            <div className="bar bar8"></div>
                        </div>
                        <h1>Fetching proposals. If there is any</h1>
                    </div>
            }
        </section>
    );
}

export default ProposalList;