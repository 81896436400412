/**
 * Author : Emre
 * Set account address and eth balance then return network id
 *
 * @param {function} setAccount function for set account state
 * @return {any} return network id
 */

import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
async function loadBCData(setAccount) {
    const web3 = window.web3;
    const accounts = await web3.eth.getAccounts();
    //Get ETH balance
    if (accounts[0] !== undefined) {
        setAccount(accounts[0]);
    } else {
        alertify.set('notifier','position', 'bottom-left');
        alertify.notify("Connect your Metamask Wallet", 'error', 5);
    }
    //fetch networkId
    const networkId = await web3.eth.net.getId();
    return networkId;
}

export default loadBCData;