
import {
  useNavigate 
} from "react-router-dom";
function Navbar() {
  const navigate = useNavigate();
  const clickToNav=(path,bg)=>{
    navigate(path);
    document.body.style.backgroundImage= `url("../images/${bg}")`;
  }
  return (
    <div className="all">
      <div className="left" onClick={()=>clickToNav("/overview","4.png")}>
          <div className="text">Governance Overview</div>
      </div>
      <div className="center" onClick={()=>clickToNav("/","black-green.jpg")}>
          <div className="explainer"><span>MENU</span></div>
          <div className="text">Proposals</div>
      </div>
      <div className="right" onClick={()=>clickToNav("/create","1.png")}>
        <div className="text">Create Proposal</div>
      </div>
    </div>
  )
}
export default Navbar;