import React, { useEffect, useState } from 'react';

function GovernanceOverview({ reitGIns, isContractsLoaded, account }) {
  const [reitGBalance, setReitGBalance] = useState(0);
  const [reitGTS, setReitGTS] = useState(0);
  const [delegateAdrs,setDelegateAdrs] = useState("");

  useEffect(() => {
    async function load() {
      if (isContractsLoaded) {
        const reitGBal = await reitGIns.methods.balanceOf(account).call();
        setReitGBalance(reitGBal.toString());
        const totSup = await reitGIns.methods.totalSupply().call();
        setReitGTS(totSup.toString());
        setDelegateAdrs(account);
      }
    }
    load();
  }, [isContractsLoaded]);
  const onChangeHandler=(e)=>{
    setDelegateAdrs(e.target.value)
  }
  const onClickDelegate=async()=>{
    console.log(delegateAdrs);
    await reitGIns.methods.delegate(delegateAdrs).send({ from: account });
  }
  return (
    <div className='overview'>
      <h1>REIT Governance Overview</h1>
      <div className='card'>
        <h5>Your REITg Balance {Number(reitGBalance / 1e18).toFixed(4)*1}</h5>
        <h5>REITg TOTAL SUPPLY {Number(reitGTS / 1e18).toFixed(4)*1}</h5>
        <h5>REITg MAX SUPPLY {12001923}</h5>
      </div>
      <div className='card delegate-section'>
        <h3>DELEGATE TOKEN</h3>
        <input type='text' value={delegateAdrs} onChange={onChangeHandler}></input>
        <br></br>
        <button className='button-29' onClick={onClickDelegate}>Delegate</button>
      </div>
      <div className='card'>
        <p>
          REIT Labs believe in a democratic, effective, reliable and collective Community Governance,which benefits all parties involved.
        </p>
        <ul className='governance-rules'>
          <li>Setting rules for proposals for any kind of change in the protocol </li>
          <ul>
            <li>Anyone with 0.1% of total REITg supply can submit a Request for Proposal (RFP)</li>
            <li>Anyone with any amount of REITg can vote for proposals </li>
            <li>All REITg owners can delegate their voting rights to another person</li>
            <li>For a proposal to pass, 2 conditions must be satisfied:</li>
            <ul>
              <li>Minimum quorum is 5% of total REITg supply</li>
              <li>FOR vote {'>'} 50% </li>
            </ul>
            <li>Admin (REIT Labs) can cancel the whole proposal process (only in Version2)</li>
            <li>The scheduling of proposals:</li>
            <ul>
              <li>Voting Delay: 2 days (57600 blocks) </li>
              <li>Voting Period: 3 days (86400 blocks)</li>
              <li>Execute Time lock: 2 days (172800 seconds)</li>
            </ul>
          </ul>
          <li>All new upgrades and how & when they will be applied into the protocol</li>
          <ul>
            <li>New investment protocols and how & when they will be applied into the protocol</li>
            <li>All type of bug fixes</li>
          </ul>
          <li>Changing some values/rates in the protocol such as</li>
          <ul>
            <li>Buy Back rate for Committers </li>
            <li>Buy Back rate for Depositors </li>
            <li>Fee rate</li>
            <li>Commitment period limits (initially set to minimum 3 – maximum 12) </li>
            <li>Decisions on investment protocols such as Venus </li>
            <li>Code upgrades (Integrating new protocols etc)</li>
            <li>Staking and all issues related (in the future versions) </li>
          </ul>
        </ul>
      </div>
    </div>
  )
}

export default GovernanceOverview;