//CONTRACTS
import ReitGContract from '../contracts/REITg.json';
import GovernorContract from '../contracts/REITGovernor.json';
import TimeLockControllerContract from '../contracts/TimelockController.json';


/**
 * Author : Emre
 * Set account address and eth balance then return network id
 * @param {function} setReitGI function for set reitGInstance state
 * @param {function} setTreasuryI function for set treasuryInstance state
 * @param {function} setGovernorI function for set comitterInstance state
 * @param {function} setTlcI function for set depositorInstance state
 */

async function loadContracts(
    setReitGI,
    setGovernorI,
    setTlcI
) {
    const web3 = window.web3;
    //fetch networkId
    const networkId = await web3.eth.net.getId();
    //Get the contract Instance
    //REIT GOVERNANCE INSTANCE
    const reitGContractNet = ReitGContract.networks[networkId];
    if (reitGContractNet) {
        const reitGTokenInstance = new web3.eth.Contract(
            ReitGContract.abi,
            reitGContractNet.address
        );
        //console.log("reitGContractNet "+reitGContractNet.address);
        setReitGI(reitGTokenInstance);
    } else {
        console.log("Failed to load Reit Governance Token Contract")
    }
    // Governor Instance
    const governorContractNet = GovernorContract.networks[networkId];
    if (governorContractNet) {
        const governorContractInstance = new web3.eth.Contract(
            GovernorContract.abi,
            governorContractNet.address
        );
        //console.log("governorContractNet "+governorContractNet.address);
        setGovernorI(governorContractInstance);
    } else {
        console.log("Failed to load GovernorContract")
    }
    // TimeLock Instance
    const tlcContractNet = TimeLockControllerContract.networks[networkId];
    if (tlcContractNet) {
        const tlcContractInstance = new web3.eth.Contract(
            TimeLockControllerContract.abi,
            tlcContractNet.address
        );
        //console.log("TimeLockControllerContract "+tlcContractInstance.address);
        setTlcI(tlcContractInstance);
    } else {
        console.log("Failed to load TimeLockControllerContract")
    }

    return true;
}

export default loadContracts;