import { useState, useEffect } from "react";
import FunctionCall from "./FunctionCall";
import Idea from "./Idea";

function CreateProposal({ governorIns, account, isContractsLoaded }) {
  useEffect(() => {

  }, [isContractsLoaded])
  return (
    <div className="tab-warpper">
      <input className="radio" id="one" name="group" type="radio" defaultChecked></input>
      <input className="radio" id="two" name="group" type="radio"></input>
      <div className="tabs">
        <label className="tab-2" id="one-tab" htmlFor="one">FUNCTION CALL</label>
        <label className="tab-2" id="two-tab" htmlFor="two">IDEA</label>
      </div>
      <div className="panels">
        <div className="panel" id="one-panel">
          <FunctionCall
            governorIns={governorIns}
            account={account}
          />
        </div>
        <div className="panel" id="two-panel">
          <Idea
            governorIns={governorIns}
            account={account}
          />
        </div>
      </div>
    </div>
  )
}
export default CreateProposal;