import { useState } from 'react';
import FunctionJSONInterfaces from './FunctionJSONInterfaces';
function FunctionCall({ governorIns, account }) {
    const [callContractAdrs, setCallContractAdrs] = useState("");
    const [callFunction, setCallFunction] = useState("");
    const [description, setDescription] = useState("");

    const createProposal = async () => {
        if(callContractAdrs==""||description==""){
            alert("No desc or no adrs")
            return;
        }
        if(callFunction.split('(').length<2 || !callFunction.includes('(') || !callFunction.includes(')')){
            alert("Invalid function call(syntax)");
            return;
        }
        let callFunctiontmp = callFunction.split('(')[0];
        const functionName = callFunctiontmp;
        callFunctiontmp = callFunction.split('(')[1];
        callFunctiontmp = callFunctiontmp.replace(')','');
        let params = [callFunctiontmp];
        if(callFunction.includes(',')){
            params = callFunctiontmp.split(',');
        }
        const functionJSONInterface = FunctionJSONInterfaces[functionName];
        /*
        console.log(functionName);
        console.log(params);
        console.log(functionJSONInterface);
        console.log("callContractAdrs "+callContractAdrs);
        console.log("description "+description);*/
        if(
            functionJSONInterface == undefined 
            || 
            params.length!=functionJSONInterface.inputs.length
        ){
            alert("Invalid function call(no-op or param error)");
            return;
        }
        const calldata = window.web3.eth.abi.encodeFunctionCall(functionJSONInterface, params);
        const tx = await governorIns.methods.propose(
            [callContractAdrs],
            [0],
            [calldata],
            description
        ).send({ from: account });
    }
    const onChangeHandler = (e) => {
        setCallContractAdrs(e.target.value)
    }
    const onChangeHandlerCF = (e) => {
        setCallFunction(e.target.value)
    }
    const onChangeHandlerD = (e) => {
        setDescription(e.target.value)
    }
    return (
        <div className="form">
            <div className="title">Function Call Proposal</div>
            <div className="subtitle"></div>
            <div className="input-container ic1">
                <input
                    id="contractAdrs"
                    className="input"
                    type="text"
                    placeholder=" "
                    required
                    onChange={onChangeHandler}
                >
                </input>
                <div className="cut"></div>
                <label htmlFor="contractAdrs" className="placeholder">Contract Address</label>
            </div>
            <div className="input-container ic2">
                <input
                    id="callFunction"
                    className="input"
                    type="text"
                    placeholder=" "
                    required
                    onChange={onChangeHandlerCF}
                >
                </input>
                <div className="cut"></div>
                <label htmlFor="callFunction" className="placeholder">FunctionName(param1,param2,..)</label>
            </div>
            <div className="textarea-container ic3">
                <textarea
                    id="description"
                    className="textarea"
                    type="text"
                    placeholder=" "
                    required
                    onChange={onChangeHandlerD}
                >
                </textarea>
                <div className="cut"></div>
                <label htmlFor="description" className="placeholder">Proposal Description</label>
            </div>
            <button type="text" className="submit" onClick={createProposal}>Propose</button>
        </div>
    )
}
export default FunctionCall;