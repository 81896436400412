export default {
    /* PROXIES (Tresury,Depositor,Committer) */
    setPendingImplementationDao: {
        name: 'setPendingImplementationDao',
        type: 'function',
        inputs: [{
            type: 'address',
            name: 'newImp'
        }]
    },
    /*FEEMANAGER */
    setFeeRate: {
        name: "setFeeRate",
        type: "function",
        inputs: [
            {
                name: "rate",
                type: "uint256"
            }
        ]
    },
    /*COMMITTER*/
    setMinCommitmentValueDao: {
        name: "setMinCommitmentValueDao",
        type: "function",
        inputs: [
            {
                name: "val",
                type: "uint256"
            }
        ]
    },
    setBuyBackRate: {
        name: "setBuyBackRate",
        type: "function",
        inputs: [
            {
                name: "newBBRate",
                type: "uint256"
            }
        ]
    },
    setTimeFrames: {
        name: "setTimeFrames",
        type: "function",
        inputs: [
            {
                name: "min",
                type: "uint8"
            },
            {
                name: "max",
                type: "uint8"
            }
        ]
    },
    /* DEPOSITOR */
    setMinDepositValue: {
        name: "setMinDepositValue",
        type: "function",
        inputs: [
            {
                name: "val",
                type: "uint256"
            }
        ]
    },
    /* TREASURY */
    setInvestmentProtocol: {
        name: "setInvestmentProtocol",
        type: "function",
        inputs: [
            {
                name: "index",
                type: "uint8"
            }
        ]
    }
};