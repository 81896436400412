import CastButtons from './CastButtons';
import ProposalHistory from './ProposalHistory';
function ProposalDetails(
    {
        governorIns, 
        p, 
        account,
        history,
        timer
    }
){
    const onQueue=async()=>{
        console.log(p)
        let descriptionHash = window.web3.utils.utf8ToHex(p.description);
        descriptionHash = window.web3.utils.keccak256(descriptionHash);
        await governorIns.methods.queue(
          p.targets,
          [0],
          p.calldatas,
          descriptionHash
          ,
        ).send({ from: account });
    }
    const onExecute=async()=>{
        let descriptionHash = window.web3.utils.utf8ToHex(p.description);
        descriptionHash = window.web3.utils.keccak256(descriptionHash);
        await governorIns.methods.execute(
          p.targets, 
          [0], 
          p.calldatas, 
          descriptionHash,
          ).send({from:account});       
    }
    return (
		<div className="info-section">
			<div className="info-inside">
                <p className="blur-text"> Proposal Id</p>
                <p className="white-text"> {p.proposalId} </p>
            </div>
            <div className="info-inside">
                <p className="blur-text"> Description</p>
                <p className="white-text">{p.description}</p>
            </div>
            <div className="info-inside">
                <p className="blur-text"> State - Timer</p>
                <p className="white-text">{p.status} - {timer}-</p>
            </div>
            <hr />
            <div className="info-wrapper">
                <div className="info-wrapper-inside"> 
                    <p className="blur-text"> For Votes</p>
                    <p className="white-text"> {(Number(p.forVotes) / 1e18)} </p>
                </div>
                <div className="info-wrapper-inside"> 
                    <p className="blur-text"> Against Votes</p>
                    <p className="white-text"> {(Number(p.againstVotes)) / 1e18} </p>
                </div>
                <div className="info-wrapper-inside"> 
                    <p className="blur-text"> Abstain Votes</p>
                    <p className="white-text"> {(Number(p.abstainVotes)) / 1e18} </p>
                </div>
            </div>
            {
                p.status == "Active" ?
                <CastButtons
                    governorIns={governorIns} 
                    proposalId={p.proposalId} 
                    account={account}
                />
                :
                null
            }
            
            {
                p.status == "Succeeded" ?
                <button className="button-29" onClick={onQueue}> Queue</button>
                :
                null
            }
            {
                p.status == "Queued" ?
                <button className="button-29" onClick={onExecute}>Execute</button>
                :
                null
            }
            <hr />            
			<div className="info-inside">
                <p className="blur-text"> Proposal History</p>
                <ProposalHistory
                    proposal={p}
                />
            </div>
        </div>
    )
}
export default ProposalDetails