function CastButtons({ governorIns, proposalId, account}){
    const castVoteFor = async () => {
      console.log("castVote " + proposalId);
      await governorIns.methods.castVote(proposalId, "1").send({ from: account });
    }
    const castVoteAgaints = async () => {
      console.log("castVoteAgaints " + proposalId);
      await governorIns.methods.castVote(proposalId, "0").send({ from: account });
    }
    const castVoteAbstain = async () => {
      console.log("castVoteAgaints " + proposalId);
      await governorIns.methods.castVote(proposalId, "2").send({ from: account });
    }
    return(
        <div className="info-wrapper">
            <div className="info-wrapper-inside"> 
                <button className="button-29" onClick={castVoteFor}>CAST FOR</button>
            </div>
            <div className="info-wrapper-inside"> 
                <button className="button-29" onClick={castVoteAgaints}>CAST AGAINST</button>
            </div>
            <div className="info-wrapper-inside"> 
                <button className="button-29" onClick={castVoteAbstain}>CAST ABSTAIN</button>
            </div>
        </div>
    )
}

export default CastButtons;
/**`
            <div className="info-wrapper">
                <div className="info-wrapper-inside"> 
                    <button className="button-29">CAST FOR</button>
                </div>
                <div className="info-wrapper-inside"> 
                    <button className="button-29">CAST AGAINST</button>
                </div>
                <div className="info-wrapper-inside"> 
                    <button className="button-29">CAST ABSTAIN</button>
                </div>
            </div>
            ` */