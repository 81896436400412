import { useEffect, useState } from "react";
import { votingDelay, votingPeriod, timeLock, apiUrl } from '../../helpers/backendinfo';

function ProposalHistory({ proposal }) {
    const [blockTimestamp, setBlockTimestamp] = useState();
    useEffect(() => {
        async function load() {
            const blockInfo = await window.web3.eth.getBlock(proposal.blockNumber);
            setBlockTimestamp(blockInfo.timestamp);
        }
        load();
    }, [])
    if (proposal.status == "Executed") {
        return (
            <div>
                <p className="white-text">
                    <a href={`https://bscscan.com/tx/${proposal.transactionHash}`} target="_blank">
                        Created at {new Date(blockTimestamp * 1000).toString()}
                    </a>
                </p>
                <p className="white-text">
                    Active at {new Date(blockTimestamp * 1000 + (votingDelay * 3000)).toString()}
                </p>
                <p className="white-text">
                    Succeeded at{new Date(blockTimestamp * 1000 + ((votingDelay + votingPeriod) * 3000)).toString()}
                </p>
                {proposal.queueTstmp ?
                    <p className="white-text">
                        <a href={`https://bscscan.com/tx/${proposal.queueTxHash}`} target="_blank">
                            Queueed at {new Date(proposal.queueTstmp * 1000).toString()}
                        </a>
                    </p>
                    :
                    null
                }
                {proposal.executeTstmp ?
                    <p className="white-text">
                        <a href={`https://bscscan.com/tx/${proposal.executeTxHash}`} target="_blank">
                            Executed at {new Date(proposal.executeTstmp * 1000).toString()}
                        </a>
                    </p>
                    :
                    null
                }
            </div>
        )
    } else if (proposal.status == "Queued") {
        return (
            <div>
                <p className="white-text">
                    <a href={`https://bscscan.com/tx/${proposal.transactionHash}`} target="_blank">
                        Created at {new Date(blockTimestamp * 1000).toString()}
                    </a>
                </p>
                <p className="white-text">
                    Active at {new Date(blockTimestamp * 1000 + (votingDelay * 3000)).toString()}
                </p>
                <p className="white-text">
                    Succeeded at {new Date(blockTimestamp * 1000 + ((votingDelay + votingPeriod) * 3000)).toString()}
                </p>
                {proposal.queueTstmp ?
                    <p className="white-text">
                        <a href={`https://bscscan.com/tx/${proposal.queueTxHash}`} target="_blank">
                            Queueed at {new Date(proposal.queueTstmp * 1000).toString()}
                        </a>
                    </p>
                    :
                    null
                }
            </div>
        )
    } else if (proposal.status == "Defeated") {
        return (
            <div>
                <p className="white-text">
                    <a href={`https://bscscan.com/tx/${proposal.transactionHash}`} target="_blank">
                        Created at {new Date(blockTimestamp * 1000).toString()}
                    </a>
                </p>
                <p className="white-text">
                    Active at {new Date(blockTimestamp * 1000 + (votingDelay * 3000)).toString()}
                </p>
                <p className="white-text">
                    Defeated at ${new Date(blockTimestamp * 1000 + ((votingDelay + votingPeriod) * 3000)).toString()}
                </p>

            </div>
        )
    } else if (proposal.status == "Canceled") {
        return (
            <div>
                <p className="white-text">
                    <a href={`https://bscscan.com/tx/${proposal.transactionHash}`} target="_blank">
                        Created at {new Date(blockTimestamp * 1000).toString()}
                    </a>
                </p>
                <p className="white-text">
                    Active at {new Date(blockTimestamp * 1000 + (votingDelay * 3000)).toString()}
                </p>
                {proposal.cancelTstmp ?
                    <p className="white-text">
                        <a href={`https://bscscan.com/tx/${proposal.cancelTxHash}`} target="_blank">
                            Canceled at {new Date(proposal.cancelTstmp * 1000).toString()}
                        </a>
                    </p>
                    :
                    null
                }
            </div>
        )
    } else {
        return (
            <div>
                <p className="white-text">
                    <a href={`https://bscscan.com/tx/${proposal.transactionHash}`} target="_blank">
                        Created at {new Date(blockTimestamp * 1000).toString()}
                    </a>
                </p>
                <p className="white-text">
                    Active at {new Date(blockTimestamp * 1000 + (votingDelay * 3000)).toString()}
                </p>

            </div>
        )
    }
}
export default ProposalHistory;